import * as Yup from 'yup'
// const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const schema = Yup.object().shape({
  OrganizationType: Yup.string().required('Organization Type is required'),
  Email: Yup.string().required('Organization Email is required'),
  OrgEIN: Yup.string().required('Organization EIN is required'),
  OrgIRSType: Yup.string().required('IRS Tax Type is required'),
  StreetAddress: Yup.string().required('Street Address is required'),
  PhoneNumber: Yup.string()
    // .matches('Invalid Phone number e.g 1234567891')
    .required('Phone number is required')
    .nullable(),
  Website: Yup.string()
    .required('Website is required')
    .url('Invalid URL format')
    .notRequired(),
  OrgDomain: Yup.string().required('OrgDomain is required'),
  Description: Yup.string().required('Description is required'),
  InternationallyFunded: Yup.string().required(
    'Internationally Funded is required',
  ),
  Logo: Yup.mixed()
    .required('Logo is required')
    .test('fileType', 'Only JPG, JPEG, and PNG files are allowed', (value) => {
      if (!value) return true
      return (
        value &&
        ['image/jpeg', 'image/png'].includes(value.type || value.fileMimeType)
      )
    }),
  BackgroundImage: Yup.mixed()
    .required('Background Image is required')
    .test('fileType', 'Only JPG, JPEG, and PNG files are allowed', (value) => {
      if (!value) return true
      return (
        value &&
        ['image/jpeg', 'image/png'].includes(value.type || value.fileMimeType)
      )
    }),
  W9Form: Yup.mixed()
    .required('W9-form is required')
    .test('fileType', 'Only PDF files are allowed', (value) => {
      if (!value) return true
      return (
        value && ['application/pdf'].includes(value.type || value.fileMimeType)
      )
    }),
  IdentityFile: Yup.mixed()
    .required('Photo ID is required')
    .test('fileType', 'Only JPG, JPEG, and PNG files are allowed', (value) => {
      if (!value) return true
      return (
        value &&
        ['image/jpeg', 'image/png'].includes(value.type || value.fileMimeType)
      )
    }),
})

const organizationUpdateAboutValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationUpdateAboutValidation }
